import React from 'react'
import './Insurance1.css'
import { Link } from 'react-router-dom'

import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

import tasweer from '../assets/InsurancePageImage.jpg'
import tasweer2 from '../assets/table-engagement.gif'

function Insurance2() {
    return (
        <>
            <div>
                <div className='insurance1Service'>
                    <h1 className='insuranceName1'>Transition Methodology</h1>
                </div>

                <div className='insurance1Bottom'>
                    <div className='insurance1Left'>
                        <Link className='Link' to="/Insurance1">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance2">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Transition Methodology
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance3">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Current Client Specialties
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Eligibility
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Benefit Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance5">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Account Receivables
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <div className='TalkToInsurance'>
                            <div className='InsuranceImage'>
                                <div className='serviceIcon' ><FiPhoneCall style={{
                                    height: '2.5rem', width: '2.5rem', color: 'white'
                                }} /></div>
                                <h1 className='AgentLargeText' style={{ textAlign: "center", color: 'white' }}>Talk to our
                                    <br />
                                    insurance agent</h1>
                                <h4 style={{ textAlign: "center", color: 'white', margin: '2rem 0 0 0' }}>+92 (003) 68-090</h4>
                                <h5 style={{ textAlign: "center", color: 'white', margin: '0' }}>Call to Our Experts</h5>
                            </div>
                        </div>
                    </div>
                    <div className='insurance1Right'>
                        <div className='rightinsuranceImage'>
                            <img className='rightinsuranceImageSize' src={tasweer} alt='...' />
                        </div>
                        <div className='rightinsuranceText'>At an organisation level any outsourcing project would involve the tangible changes in structure, processes, technology, culture that the organisation would go through from the current state to the desired future state. At the individual level, it would involve the processes needed for the individuals to adjust to the new way of working.

                            Our structured approach to outsourcing & consistent track record of successful execution has helped us build credibility and a position as “your most preferred outsourcing partner”.

                            Our comprehensive methodology takes care of all three phases of the Outsourcing Services Transitioning; Pre-Transition, Transition and Post Transition/ Steady phase.
                            <br /><br />

                            <h3>Transition Team</h3>


                            Unity Health System has a dedicated transition management team. It is an experienced Transition Team comprising of multidisciplinary expertise in Operations, Quality & Information Technology and trained in Business Process Transition and Project Management to help integrate all aspects of the outsourcing solution. The team is capable of analyzing outsourcing potential and advising risk mitigation strategies. This expertise of the core team combined with relevant experience and skills help Unity Health System always deliver best-fit transition solution.
                            <br /><br />

                            <div className='rightinsuranceImage'>
                                <img className='rightinsuranceImageSize2' src={tasweer2} alt='...' />
                            </div>

                            <h3>Post Implementation Evaluation Report (PIER)</h3>

                            A Post Implementation Evaluation Report (PIER) documents the successes and failures of the project. It provides a historical record of the planned and actual budget and schedule. Other selected metrics on the project can also be collected, based upon organization procedures. The report also contains recommendations for other projects of similar size and scope.
                            <br /><br />

                            <h3>Contents of PIER are :</h3>

                            › Initial Evaluation and Management specialized
                            › Independent Medical Evaluation
                            › History and Physical Examination Reports
                            › Consultation
                            › Operative Notes
                            › Progress Notes
                            › SOAP Notes
                            › Discharge Summary
                            › Pathology Report
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Insurance2
