import React, { useState } from 'react'
import './Career.css'
import { IoIosArrowDropdownCircle } from "react-icons/io";



export default function Career() {

    const [activeCollapsible, setActiveCollapsible] = useState(null);

    const handleCollapsibleClick = (id) => {
        setActiveCollapsible(id === activeCollapsible ? null : id);
    };


    return (
        <>
            <div className="careerPage">
                <div className="CareerPageIMg"></div>
                <div className="headSpanCareerPage">
                    <span>Current Openings</span>
                </div>
                <div className="contnetCareerPage">
                    <div id="medicalCoders" className={`collapsible ${activeCollapsible === 'medicalCoders' ? 'active' : ''}`} onClick={() => handleCollapsibleClick('medicalCoders')}>
                        <p>Medical Coders <IoIosArrowDropdownCircle /></p>
                        <div className="content" style={{ display: activeCollapsible === 'medicalCoders' ? 'block' : 'none' }}>
                            <div className="boxContent">
                                <p>Accomplished Medical Coders with minimum two years experience in Diagnostic, Interventional, Surgical, DRG, Physician or Anesthesia coding.</p>
                                <h3>Vacancy Status – Open</h3>
                                <h3>No. of posts – 10</h3>
                                <p>Post your resume</p>
                            </div>

                        </div>
                    </div>

                    <div id="arExecutive" className={`collapsible ${activeCollapsible === 'arExecutive' ? 'active' : ''}`} onClick={() => handleCollapsibleClick('arExecutive')}>
                        <p>AR Executive (Claims Management) <IoIosArrowDropdownCircle /></p>
                        <div className="content" style={{ display: activeCollapsible === 'arExecutive' ? 'block' : 'none' }}>
                        <div className="boxContent">
                                <p>Candidates with 2-4 years of AR follow up experience need apply.</p>
                                <h3>Vacancy Status – Open</h3>
                                <h3>No. of posts – 25</h3>
                                <p>Post your resume</p>
                            </div>
                        </div>
                    </div>

                    <div id="Medical" className={`collapsible ${activeCollapsible === 'Medical' ? 'active' : ''}`} onClick={() => handleCollapsibleClick('Medical')}>
                        <p>Medical Billers <IoIosArrowDropdownCircle /></p>
                        <div className="content" style={{ display: activeCollapsible === 'Medical' ? 'block' : 'none' }}>
                        <div className="boxContent">
                                <p>should have an experience of 2-5 years in charge entries and payment postings.</p>
                                <h3>Vacancy Status – Open</h3>
                                <h3>No. of posts – 15</h3>
                                <p>Post your resume</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
