import React from 'react'
import './AboutPages1.css'
import firstImg from "../assets/passion (1).jpg"
import secondImg from "../assets/hush-naidoo-jade-photography-yo01Z-9HQAw-unsplash.jpg"

export default function AboutPages1() {
    return (
        <>


            <div className="aboutPages">
                <div className="headSpan">
                    <span>PrimeCare Alliance Today</span>
                </div>
                
                <div className="secondSection">
                    <div className="secondImg">
                        <img src={secondImg} alt="" />
                    </div>
                    <div className="ContentSecond">
                        <h4>As your ally in delivering all of your healthcare business office services, PrimeCare Alliance offers reliable and cost effective solutions in today's busy healthcare industry. Today, ASC’s, Hospitals, multi-specialty as well as single specialty practices and other healthcare providers need only one partner to manage all of their revenue management programs. Our large pool of highly trained experienced process oriented personnel ensure effective processes, minimizing operational and business risks of outsourcing.</h4>

                        <div>While we concentrate on managing a superior, best practice operation, we are focused on being an ‘intelligent’ business partner. Our distinct infrastructure, superior talent and persistent focus on quality continually maximizes value for our clients.</div>
                    </div>
                </div>
                <div className="firstSection">
                    <div className='contentFirst'>
                        The world continues to get smaller. Boundaries are dissolving. Knowledge can be found instantaneously using Google or other search engines. The internet, phone calls using VOIP [voice over internet provider], SKYPE video conferencing eliminates borders and expands resources. Even established businesses find it challenging in today’s environment of change to maximize their potential and drive sustainable growth. In the practice of medicine we are seeing this change also. From EICU’s where the Intensivist is in Maryland the ICU bed is in Wyoming, to Radiologist reading an MRI in St. Louis or Australia for a hospital in Texas, to telemedicine where patient's will see a provider by Web Cam, to ICD 10 healthcare is changing. The ability to adapt to the changes today to prepare for tomorrow will allow organizations to grow. With the increase in expenses, reductions in reimbursement and placing more of the economic burden on the patient, Medical groups much look for alternatives in reducing costs. By partnering with PrimeCare Alliance your group can stabilize their costs and eliminate fluctuations when revenues may dip for whatever reason. The knowledge of Revenue Cycle Management is still a trade learned but can be globalized in its delivery.This globalization has inspired the inauguration of PrimeCare Alliance. With offices in US and India we offer our clients a plethora of cost effective, flexible and innovative services that will accelerate economic benefits in their businesses.
                    </div>

                    <div className="FirstImgsection">
                        <img src={firstImg} alt="" />
                    </div>

                </div>


            </div>

        </>

    )
}
