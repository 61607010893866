import React from 'react'
import './AboutPages3.css'
import firstImg from "../assets/people.jpg"
import secondImg from "../assets/people (2).jpg"

export default function AboutPages3() {
    return (
        <>
            <div className="aboutPages3">
                <div className="headSpan3">
                    <span>Passion & People</span>
                </div>
                <div className="secondSection3">
                    <div className="secondImg3">
                        <img src={secondImg} alt="" />
                    </div>
                    <div className="headSpan3">
                        <span>Our Passion</span>
                    </div>
                    <div className="ContentSecond3">
                        <h4>At PrimeCare Alliance our guiding principle is innovation. Healthcare is a rapidly changing and increasingly complex world. We are constantly looking for state-of-the-art solutions allowing us to provide an improved worth and support to our customers. We are proud of our ability to anticipate and react to future events. This futuristic approach has prepared us to adapt to challenges of a changing business and competitive industry. Our passion to serve our customers in every endeavor and to exceed the expectations of our customer is the motivation behind our standards.</h4>

                        <div className="headSpan3">
                            <span>Our Values</span>
                        </div>
                        <div className='Aboutlist3'>
                            <ul className='AboutUl3'>
                                <li className='aboutli3'>Honor our commitments</li>
                                <li className='aboutli3'>Integrity to do what we are contracted to do when we are supposed to do them</li>
                                <li className='aboutli3'>Innovate, be a leader not a follower.</li>
                                <li className='aboutli3'>Train your staff to lead, be collaborative, to share your knowledge</li>
                                <li className='aboutli3'>Maintain a friendly work place where people will want to come to the office</li>
                                <li className='aboutli3'>Engage in open communication with no retaliation for disagreement</li>
                                <li className='aboutli3'>Celebrate Success, stay passionate in what you do</li>
                                <li className='aboutli3'>Treat everyone with the same respect every human being is entitled</li>
                            </ul>
                        </div>


                    </div>
                </div>
                <div className="headSpan3">
                    <span>Our People</span>
                </div>
                <div className="firstSection3">
                    <div className="FirstImgsection3">
                        <img src={firstImg} alt="" />
                    </div>
                    <div className='contentFirst3'>
                        <h1 >At PrimeCare Alliance, people are our key resource. Our Personnel strategy focuses on recruiting, nurturing, deploying and retaining high quality talent to ensure maximum impact on projects and services.</h1>
                    </div>

                </div>
                <div className="headSpan3">
                    <span>Our Strategy Focuses On:</span>
                </div>
                <div className='Aboutlist3'>
                    <ul className='AboutUl3'>
                        <li className='aboutli3'>Recruitment and retaining talent with relevant skills, knowledge, and experience.</li>
                        <li className='aboutli3'>Deploying the right people to the right project to deliver maximum value to our customers.</li>
                        <li className='aboutli3'>Huge investments in training, learning, and domain knowledge to enable employees to sharpen and refine skills.</li>
                    </ul>
                </div>
                <p>In today's economy, you have to be good today and better tomorrow. Flexibility is critical and you cannot achieve it alone.

                </p>



            </div>


        </>
    )
}
