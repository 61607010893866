import React from "react";
import { Link } from 'react-router-dom';
import './HoverMenu.css';

export default function HoverMenu({ title,onClick }) {
    return (
        <div className="dropdown">
            <button className="dropbtn">{title}</button>
            <div className="dropdown-content">
            <Link  onClick={onClick} className='Link' to="/About">OverView</Link>
            <Link  onClick={onClick} className='Link' to="/Northwest-Today">PrimeCare Alliance Today</Link>
            <Link  onClick={onClick} className='Link' to="/Northwest-Tomorrow">PrimeCare Alliance Tomorrow</Link>
            <Link  onClick={onClick} className='Link' to="/Passion-&-People">Passion & People</Link>
            {/* <Link  onClick={onClick} className='Link' to="/Leadership">Leadership</Link> */}
            </div>
        </div>
    );
}
