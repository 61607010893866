import React from 'react'
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";

import './Navbar1.css';

export default function Navbar1() {
  return (
    <>
      <div className="nav1">
        <div className="nav1Content">
          <div className='Nav1phone'><FaPhone />(00) 875 784 5682</div>
          <div className='Nav1email'><MdEmail />Insurinfo@gmail.com</div>
          {/* <div className="socialMediaNav1">
            <div className="insta">
              <FaInstagram />
              <FaFacebookF />
              <FaTwitter />
            </div>
          </div> */}

        </div>

      </div>
    </>
  )
}
