import React, { useState } from 'react';
import { FaPhone } from "react-icons/fa";

import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify'; // Import the toast function from react-toastify
import './Contact.css'

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Destructure form data
        const { name, email, phone, subject, message } = formData;

        // Check if required fields are filled
        if (!name || !email || !phone || !subject || !message) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Fill all the fields!"
              });
            return;
        }

        // // EmailJS parameters
        // const templateParams = {
        //     from_name: name,
        //     from_email: email,
        //     phone: phone,
        //     subject: subject,
        //     message: message,
        // };

        // // EmailJS service ID, template ID, and user ID
        // const serviceID = '';
        // const templateID = '';
        // const userID = '';


        // // Send the email
        // emailjs.send(serviceID, templateID, templateParams, userID)
        //     .then((response) => {
        //         // console.log('Email sent successfully!', response.status, response.text);
        //         // toast.success("Send Successfully!", { position: "top-center" }); 
        //         Swal.fire({
        //             position: "center",
        //             icon: "success",
        //             title: "Send Successfully!",
        //             showConfirmButton: false,
        //             timer: 2000
        //         });
        //         setFormData({
        //             name: '',
        //             email: '',
        //             phone: '',
        //             subject: '',
        //             message: ''
        //         });
        //     })
        //     .catch((error) => {
        //         console.error('Error sending email:', error);
        //         toast.error("Failed to send email", { position: "top-center" });
        //     });
    };

    return (
        <>
            <div className="contactpage">
                <div className="ContactBanner">
                    <h1>Contact Us</h1>
                    <p>Feel free to get in touch </p>
                </div>
                <div className="contact">
                    <div className='ContactInfo'>
                        <span>CONTACT US</span>
                        <h1>Feel free to get in touch with experts</h1>
                        <div className="callsection">
                            <div className="iconCallSection">
                                <FaPhone />
                            </div>
                            <div className='callsectionP'>
                                <p>+92 (003) 68-090</p>
                                <p>needhelp@company.com</p>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className="ContactFormsSecton">
                        <div className="inputFileds">
                            <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your name"  />
                            <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email address"  />
                        </div>
                        <div className="inputFileds">
                            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone number" />
                            <input type="text" name="subject" value={formData.subject} onChange={handleChange} placeholder="Subject"  />
                        </div>
                        <textarea name="message" value={formData.message} onChange={handleChange} cols="30" rows="10" placeholder="Message" ></textarea>
                        <button type="submit" className="button-30">Submit Message</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
