import React from 'react'
import './Insurance1.css'
import { Link } from 'react-router-dom'

import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

import tasweer from '../assets/first (1).jpg'
import tasweer1 from '../assets/EngagementModel.jpg'

function Insurance1() {
    return (
        <>
            <div>
                <div className='insurance1Service'>
                    <h1 className='insuranceName1'>Insurance Verification</h1>
                </div>

                <div className='insurance1Bottom'>
                    <div className='insurance1Left'>
                        <Link className='Link' to="/Insurance1">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance2">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Transition Methodology
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance3">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Current Client Specialties
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Eligibility
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Benefit Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance5">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Account Receivables
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <div className='TalkToInsurance'>
                            <div className='InsuranceImage'>
                                <div className='serviceIcon' ><FiPhoneCall style={{
                                    height: '2.5rem', width: '2.5rem', color: '#dc7535'
                                }} /></div>
                                <h1 className='AgentLargeText' style={{ textAlign: "center", color: 'white' }}>Talk to our
                                    <br />
                                    insurance agent</h1>
                                <h4 style={{ textAlign: "center", color: 'white', margin: '2rem 0 0 0' }}>+92 (003) 68-090</h4>
                                <h5 style={{ textAlign: "center", color: 'white', margin: '0' }}>Call to Our Experts</h5>
                            </div>
                        </div>
                    </div>
                    <div className='insurance1Right'>
                        <div className='rightinsuranceImage'>
                            <img className='rightinsuranceImageSize' src={tasweer} alt='...' />
                        </div>
                        <div className='rightinsuranceText'>Industry sources state, 70 to 75% of all health care claim are denied are due to a patient not being eligible for services billed to the insurer by the provider. Often, a patient would be ineligible for benefits because his or her policy has been terminated or modified, specific services are ineligible or benefits are limited.

                        <br/>
                        <br/>


                            Unity Health System can assist practices reduce their denials and its accounts receivable cycle while increasing revenue. In the verification of benefits we dramatically reduce the impact of ineligibility and increase the number of claims that are sent to insurers for adjudication. Benefit and eligibility verification is one of the most neglected elements that impact collections in the revenue cycle of the health care industry.
                            <br/>
                        <br/>
                            In the absence of proper eligibility and benefit verification countless numerous problems are created i.e., denied or delayed payments, reprocessing, decreased patient and staff satisfaction, increased costs of denial and appeals management. To avoid these problems, Unity Health System deploys staff, technology, expertise and management delivering cost effective patient benefit and insurance eligibility services.
                            <br/>
                        <br/>
                            Unity Health System Eligibility Verification Services has the potential to:

                            <br/><br/>
                            › Improve cash flow
                            <br/>
                            › Decrease denials and appeals of claims
                            <br/>
                            › Decrease providing non covered services
                            <br/>
                        <br/>

                        <div className='rightinsuranceImage'>
                            <img className='rightinsuranceImageSize' src={tasweer1} alt='...' />
                        </div>

                        <br/>
                            Our Insurance Eligibility & Benefits Verification Services Include:
                            <br/><br/>
                            › Obtain Schedules from the practices via various forms of media
                            <br/>
                            › Verify coverage on all Primary and Secondary Payers (if applicable)
                            <br/>
                            › Contact patient for information if necessary (Client Option)
                            <br/>
                            › Provide the client with the results which include eligibility and benefits information such as member ID, group ID, coverage end and start dates, co-pay/co-insurance information in and out of network deductibles with maximum spending amounts.
                            <br/>
                        <br/>
                            Related Services (optional):
                            <br/><br/>
                            › Obtain Pre- Authorization Number
                            <br/>
                            › Obtain referral from PCP
                            <br/>
                            › Update Patient demographics
                            <br/>
                            › Remind patient of patient payment requirements
                            <br/>
                            › Inform client if there is an issue with coverage or Authorization</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Insurance1
