import React from "react";
import { Link } from 'react-router-dom';
import './HoverMenu.css';

export default function HoverMenu2({ title,onClick }) {
    return (
        <div className="dropdown">
            <button className="dropbtn">{title}</button>
            <div className="dropdown-content">
            <Link  onClick={onClick} className='Link' to="/Insurance1">Insurance Verification</Link>
            <Link  onClick={onClick} className='Link' to="/Insurance2">Transition Methodology</Link>
            <Link  onClick={onClick} className='Link' to="/Insurance3">Current Client Specialties</Link>
            <Link  onClick={onClick} className='Link' to="/Insurance4">Insurance Eligibility & Benefit Verification</Link>
            <Link  onClick={onClick} className='Link' to="/Insurance5">Account Receivables</Link>
            </div>
        </div>
    );
}
