import React from 'react'
import './Services.css'

import SingleCards from '../component/SingleCards'

// import pexel1 from '../assets/pexels1.jpg';
import image2 from '../assets/insurancedetails.jpg';
import { FiPhoneCall } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { SiFastapi } from "react-icons/si";

function Services() {
      return (
            <>
                  <div>
                        <div className='servicesection'>
                              <h1 className='serviceText'>INSURANCE SERVICES</h1>
                        </div>

                        <div className='cardSection'>
                              <SingleCards />

                        </div>
                  </div>
                  <div className='ServiceBottomSection'>
                        
                              <div className='TalkToAgent'>
                                    <div className='agentImage'>
                                          <div className='serviceIcon' ><FiPhoneCall style={{
                                                height: '2.5rem', width: '2.5rem', color: 'white'
                                          }} /></div>
                                          <h1 className='AgentLargeText' style={{ textAlign: "center", color: 'white' }}>Talk to our
                                                <br />
                                                insurance agent</h1>
                                          <h4 style={{ textAlign: "center", color: 'white', margin: '2rem 0 0 0' }}>+92 (003) 68-090</h4>
                                          <h5 style={{ textAlign: "center", color: 'white', margin: '0' }}>Call to Our Experts</h5>
                                    </div>
                              </div>
                       
                        <div className='mainFeatures'>
                              <div className='searviceFearture'>
                                    <div className='featureImage'>
                                          <img className='featureImage' src={image2} alt='Loading...' />
                                          <div className="insurancedetailsText">
                                                <p>
                                                      Hope for the Best,
                                                      <br />
                                                      Prepare for the Worst
                                                </p>
                                          </div>
                                    </div>
                                    <div className='featureText'>
                                          <div>
                                                <div className='featureTextheading'>Main features</div>
                                                <div className='featureTextheading2'>There are many variations of passages of available but the majority have suffered alteration in some form, by injected hum randomised words which don't slightly.</div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                      <ul style={{ listStyle: 'none', margin: '0', paddingLeft: '1rem' }}>
                                                            <li>
                                                                  <div style={{ display: 'flex', marginTop: '1rem' }}>
                                                                        <div className='SecrviceIconList' style={{ height: '1rem' }}><FaCheckCircle style={{ color: 'rgb(12, 224, 255)' }} /></div>
                                                                        <div className='SecrvicetextList' style={{ marginLeft: '1rem', fontSize: '1.1rem' }}>International Plans
                                                                        </div>
                                                                  </div>
                                                            </li>
                                                            <li>
                                                                  <div style={{ display: 'flex', marginTop: '1rem' }}>
                                                                        <div className='SecrviceIconList' style={{ height: '1rem' }}><FaCheckCircle style={{ color: 'rgb(12, 224, 255)' }} /></div>
                                                                        <div className='SecrvicetextList' style={{ marginLeft: '1rem', fontSize: '1.1rem' }}>International Plans
                                                                        </div>
                                                                  </div>
                                                            </li>
                                                            <li>
                                                                  <div style={{ display: 'flex', marginTop: '1rem' }}>
                                                                        <div className='SecrviceIconList' style={{ height: '1rem' }}><FaCheckCircle style={{ color: 'rgb(12, 224, 255)' }} /></div>
                                                                        <div className='SecrvicetextList' style={{ marginLeft: '1rem', fontSize: '1.1rem' }}>International Plans
                                                                        </div>
                                                                  </div>
                                                            </li>
                                                            <li>
                                                                  <div style={{ display: 'flex', marginTop: '1rem' }}>
                                                                        <div className='SecrviceIconList' style={{ height: '1rem' }}><FaCheckCircle style={{ color: 'rgb(12, 224, 255)' }} /></div>
                                                                        <div className='SecrvicetextList' style={{ marginLeft: '1rem', fontSize: '1.1rem' }}>International Plans
                                                                        </div>
                                                                  </div>
                                                            </li>

                                                      </ul>
                                                </div>
                                          </div>

                                    </div>


                              </div>

                              <div className='qualityCards'>
                                    <div className='qualityCard1'>
                                          <div className='qualityIcon'><SiFastapi style={{
                                                height: '3.8rem', width: '3.8rem', color: 'rgb(12, 224, 255)'
                                          }} /></div>
                                          <div className='qualityText'>Fast & Easy
                                                Process</div>
                                          <div className='qualityLine'></div>
                                          <div className='qualityPara'>There are many not of age of dirm available the simply free text available in the market today you can use them majority.</div>
                                    </div>
                                    <div className='qualityCard1'>
                                          <div className='qualityIcon'><GiNotebook style={{
                                                height: '3.8rem', width: '3.8rem', color: 'rgb(12, 224, 255)'
                                          }} /></div>
                                          <div className='qualityText'>Quick Claim
                                                Handle</div>
                                          <div className='qualityLine'></div>
                                          <div className='qualityPara'>There are many not of age of dirm available the simply free text available in the market today you can use them majority.</div>
                                    </div>
                                    <div className='qualityCard1'>
                                          <div className='qualityIcon'><GiMoneyStack style={{
                                                height: '3.8rem', width: '3.8rem', color: 'rgb(12, 224, 255)'
                                          }} /></div>
                                          <div className='qualityText'>Save Your
                                                Money</div>
                                          <div className='qualityLine'></div>
                                          <div className='qualityPara'>There are many not of age of dirm available the simply free text available in the market today you can use them majority.</div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      )
}

export default Services
