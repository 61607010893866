import React from 'react'
import { Link } from 'react-router-dom';
import { TbHomeHeart } from "react-icons/tb";
// import { SiKnowledgebase } from "react-icons/si";
import { GiTeamIdea } from "react-icons/gi";
import { TbCloudDataConnection } from "react-icons/tb";
import { MdOutlineAccountBalance } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { VscGistSecret } from "react-icons/vsc";

import image from '../assets/serviceCard.jpg'
import image1 from '../assets/Differentiators.jpg'
import image2 from '../assets/Medical Coding.jpg'
import image3 from '../assets/Accounts Receivable.jpg'
import image4 from '../assets/EngagementModel.jpg'
import image5 from '../assets/Security.jpg'
import image6 from '../assets/Confidentiality.jpg'

function SingleCards() {
    return (
        <>
            <Link className='Link' to='/Services/Insurance1'><div className='singleCard'>
                <div className='cardimage'>
                    <img className='cardimage' src={image1} alt='loading..' />
                </div>
                <div className='cardText'>
                    <h4 className='cardLargeText'>Differentiators</h4>
                    <div className='cardSmallText'>
                        Lorem ipsum dolor sit amet, sed consectetur adipiscing elit.
                    </div>
                </div>
                <div className='cardIcon'>
                    <GiTeamIdea style={{
                        height: '3.5rem', width: '3.5rem', color: 'rgb(59, 59, 233)'
                    }} />
                </div>

            </div>
            </Link>
            <div className='singleCard'>
                <div className='cardimage'>
                    <img className='cardimage' src={image2} alt='loading..' />
                </div>
                <div className='cardText'>
                    <h4 className='cardLargeText'>Medical Coding</h4>
                    <div className='cardSmallText'>
                        Lorem ipsum dolor sit amet, sed consectetur adipiscing elit.
                    </div>
                </div>
                <div className='cardIcon'>
                    <TbHomeHeart style={{
                        height: '3.5rem', width: '3.5rem', color: 'rgb(59, 59, 233)'
                    }} />
                </div>

            </div>
            <div className='singleCard'>
                <div className='cardimage'>
                    <img className='cardimage' src={image3} alt='loading..' />
                </div>
                <div className='cardText'>
                    <h4 className='cardLargeText'>Accounts Receivable</h4>
                    <div className='cardSmallText'>
                        Lorem ipsum dolor sit amet, sed consectetur adipiscing elit.
                    </div>
                </div>
                <div className='cardIcon'>
                    <MdOutlineAccountBalance style={{
                        height: '3.5rem', width: '3.5rem', color: 'rgb(59, 59, 233)'
                    }} />
                </div>

            </div>
            <div className='singleCard'>
                <div className='cardimage'>
                    <img className='cardimage' src={image4} alt='loading..' />
                </div>
                <div className='cardText'>
                    <h4 className='cardLargeText'>Engagement Model</h4>
                    <div className='cardSmallText'>
                        Lorem ipsum dolor sit amet, sed consectetur adipiscing elit.
                    </div>
                </div>
                <div className='cardIcon'>
                    <TbCloudDataConnection style={{
                        height: '3.5rem', width: '3.5rem', color: 'rgb(59, 59, 233)'
                    }} />
                </div>

            </div>
            <div className='singleCard'>
                <div className='cardimage'>
                    <img className='cardimage' src={image5} alt='loading..' />
                </div>
                <div className='cardText'>
                    <h4 className='cardLargeText'>Security </h4>
                    <div className='cardSmallText'>
                        Lorem ipsum dolor sit amet, sed consectetur adipiscing elit.
                    </div>
                </div>
                <div className='cardIcon'>
                    <MdOutlineSecurity style={{
                        height: '3.5rem', width: '3.5rem', color: 'rgb(59, 59, 233)'
                    }} />
                </div>

            </div>
            <div className='singleCard'>
                <div className='cardimage'>
                    <img className='cardimage' src={image6} alt='loading..' />
                </div>
                <div className='cardText'>
                    <h4 className='cardLargeText'>Confidentiality</h4>
                    <div className='cardSmallText'>
                        Lorem ipsum dolor sit amet, sed consectetur adipiscing elit.
                    </div>
                </div>
                <div className='cardIcon'>
                    <VscGistSecret style={{
                        height: '3.5rem', width: '3.5rem', color: 'rgb(59, 59, 233)'
                    }} />
                </div>

            </div>

        </>
    )
}

export default SingleCards
