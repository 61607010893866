import React from 'react'
import { Link } from 'react-router-dom';

import './Footer.css'
import { FaPaperPlane } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
// import icon from '../assets/icons8-location-96.png'
// import icon1 from '../assets/icons8-phone-96.png'
// import icon2 from '../assets/icons8-at-sign-96.png'

export default function Footer() {
    return (
        <>
            <div className="Footer">

            <div className="FooterSec">
                    <div className="logoSecFooter">
                        <div className="logo">
                            {/* <img src="" alt="" /> */}
                            <h1>PrimeCare Alliance</h1>
                        </div>
                        <div className="LogoDisFooter">
                            <span className='LogoDisFooterP'>PrimeCare Alliance is an International “REVENUE CYCLE MANAGEMENT’ Company offering solutions that optimizes processes impacting income and costs. Our entire business focus is providing services to the Healthcare Industry. </span>
                        </div>
                    </div>
                    <div className="linksFooter">
                                <h2>USEFUL LINKS</h2>
                                <Link className='FooterLink' to="/" ><span>Home</span></Link>
                                <Link className='FooterLink' to="/About" ><span>About</span></Link>
                                <Link className='FooterLink' to="/Contact" ><span>Contact</span></Link>
                                <Link className='FooterLink' to="/Career" ><span>Career</span></Link>

                            </div>

                </div>

                
                <div className="QuickContact">
                    <div className="QucikDetails">
                        <div className="LeftQuick">
                            <h3>QUICK CONTACTS</h3>
                            <div className="ListQuickDetails">
                                <div className="addresBox">
                                    <div className="icon">
                                        <FaPhone />
                                    </div>
                                    <span className="textQuick">
                                        +9833929292
                                        +9833533290
                                    </span>
                                </div>
                                <div className="addresBox">
                                    <div className="icon">
                                        <MdEmail />
                                    </div>
                                    <span className="textQuick">
                                        info@technum.com technum@mail.com
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="RightQuick">
                           
                        </div>
                    </div>
                </div>
             

            </div>
            <div className="copyRightSectionFooter">
                <h4>© 2023 PrimeCare Alliance. All Rights Reserved</h4>
            </div>
        </>
    )
}
