import React from 'react'
import './Insurance1.css'
import { Link } from 'react-router-dom'

import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

import tasweer from '../assets/first (1).jpg'
import tasweer1 from '../assets/EngagementModel.jpg'

function Insurance1() {
    return (
        <>
            <div>
                <div className='insurance1Service'>
                    <h1 className='insuranceName1'>Account Receivables</h1>
                </div>

                <div className='insurance1Bottom'>
                    <div className='insurance1Left'>
                        <Link className='Link' to="/Insurance1">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance2">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Transition Methodology
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance3">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Current Client Specialties
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Insurance Eligibility
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance4">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Benefit Verification
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <Link className='Link' to="/Insurance5">
                            <div className='insuranceList'>
                                <div className='insuranceListText'>
                                    Account Receivables
                                </div>
                                <div className='insuranceListArrow'>
                                    <HiOutlineArrowNarrowRight className='IconInsurance' />
                                </div>
                            </div>
                        </Link>
                        <div className='TalkToInsurance'>
                            <div className='InsuranceImage'>
                                <div className='serviceIcon' ><FiPhoneCall style={{
                                    height: '2.5rem', width: '2.5rem', color: 'white'
                                }} /></div>
                                <h1 className='AgentLargeText' style={{ textAlign: "center", color: 'white' }}>Talk to our
                                    <br />
                                    insurance agent</h1>
                                <h4 style={{ textAlign: "center", color: 'white', margin: '2rem 0 0 0' }}>+92 (003) 68-090</h4>
                                <h5 style={{ textAlign: "center", color: 'white', margin: '0' }}>Call to Our Experts</h5>
                            </div>
                        </div>
                    </div>
                    <div className='insurance1Right'>
                        <div className='rightinsuranceImage'>
                            <img className='rightinsuranceImageSize' src={tasweer} alt='...' />
                        </div>
                        <div className='rightinsuranceText'>
                            Cash flow and revenue management are among the most critical factors in any professionally run practice. At NWRCM, we can assist you in driving your business growth by effectively managing your cash and revenue flow, by reducing the accounts receivable days and improving profitability by increasing the collection ratio. NorthwestRCM has a large team of AR follow up professionals who can identify patient accounts that needs to be followed up and take necessary action to collect unpaid claims and underpaid claims.

                            <br />
                            <br />


                            <h3>Our Services in Account Receivable include:</h3>

                            AR analysis<br /><br />
                            Insurance follow-up<br /><br />
                            Self pay follow-up
                            <br />
                            <br />

                            <div className='rightinsuranceImage'>
                                <img className='rightinsuranceImageSize' src={tasweer1} alt='...' />
                            </div>

                            <br />
                            <h3>NWRCM Services also include on time reporting of:</h3>

                            Payer punctuality<br /><br />
                            Payer Mix<br /><br />
                            Aging analysis, Procedure code analysis, Insurance analysis, cash collection analysis, under payment analysis expected collection report<br /><br />
                            Charges, payments and adjustments<br /><br />
                            Our proven cash flow and revenue management services will improve operational cash flow by reducing bad debit and write-offs. We manage customer revenue risk while at the same time improving customer satisfaction.</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Insurance1
