import React from 'react'
import { Link } from 'react-router-dom';

import './Home.css';
import { FaPaperPlane } from "react-icons/fa6";
import logo1 from "../assets/icons8-protect-50.png"
import logo2 from "../assets/icons8-money-bag-48.png"
import logo3 from "../assets/icons8-fast-50.png"
import about from "../assets/about (2).jpg"
import choose from "../assets/whyweChoose.jpg"
import save from "../assets/save (1).png"
import anytime from "../assets/anytime.png"
import fast from "../assets/fast2.png"
import coma from "../assets/coma.png"
import { FaChevronCircleRight } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { SiAngellist } from "react-icons/si";
import { FaLocationArrow } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";

import { ImUnlocked } from "react-icons/im";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
import { Navigation } from 'swiper/modules';



export default function Home() {
    return (
        <>
            <div className="HomeContent">
                <h1>Welcome to PrimeCare Alliance</h1>
                <h5>REVENUE CYCLE MANAGEMENT</h5>
            </div>
            <div className="Home">
                <div className="featuresHomePage">
                    <div className="HomeCard">
                        <div className="imgcard"></div>
                        <div className="imgLogo">
                            <ImUnlocked style={{color:"#d8c08b", scale:"3"}} />
                        </div>
                        <h1>Legally maximize revenues</h1>
                        <h4> Unlock the full potential of your healthcare organization's revenue streams with PrimeCare Alliance.</h4>

                    </div>
                    <div className="HomeCard">
                        <div className="imgcard"></div>
                        <div className="imgLogo">
                        <FaMoneyBillTrendUp style={{color:"#d8c08b", scale:"3"}}/>
                        </div>
                        <h1>Improve efficiencies and utilize economy of scale</h1>
                        <h4>Drive efficiency and harness the power of economy of scale with PrimeCare Alliance.</h4>
                    </div>
                    <div className="HomeCard">
                        <div className="imgcard"></div>
                        <div className="imgLogo">
                        <FaUserTie style={{color:"#d8c08b", scale:"3"}}/>
                        </div>
                        <h1>Optimize customer satisfaction, patient, provider and staff</h1>
                        <h4>Elevate customer satisfaction across all fronts patients, providers, and staff  with us</h4>
                    </div>
                </div>
            </div>

            <div className="aboutCompany">
                <div className="aboutImgSection">
                    <div className="aboutImg">
                        <img src={about} alt="" />
                    </div>
                    <div className="cardabout">
                        <h1>25<span>+</span></h1>
                        <h3>YEARS WORKING EXPERIENCE</h3>
                    </div>

                </div>
                <div className="aboutContentSection">
                    <span>ABOUT OUR COMPANY</span>
                    <h1>Innovative Solutions for Revenue Optimization in Healthcare</h1>
                    <h5>PrimeCare Alliance is an International “REVENUE CYCLE MANAGEMENT’ Company offering solutions that optimizes processes impacting income and costs. Our entire business focus is providing services to the Healthcare Industry. Our expertise in practice management allows us to deploy “best practice” guidelines while incorporating our domain knowledge to complement our clients ‘requirements.</h5>
                    <div className="aboutContentSection2">
                        <div className="keyAbout">
                            <h5><FaChevronCircleRight style={{ scale: "1.4" }} /> Reduce operational costs</h5>
                            <h5><FaChevronCircleRight style={{ scale: "1.4" }} /> Implement Process Improvement through Analytics</h5>
                            <h5><FaChevronCircleRight style={{ scale: "1.4" }} /> Expand knowledge through communication</h5>
                            <Link className='Link' style={{ color: "white", zIndex: "2" }} to="/Contact">
                                <div className='getIntouchAbout'>
                                    <p className='contantAbout'>Contact<FaPaperPlane /></p>
                                </div>
                            </Link>

                        </div>
                        {/* <div className="contentCard">
                            <div className="rate">
                                <h1>100%</h1>
                                <h5>Success Rate</h5>
                            </div>
                            <div className="lineabout"></div>
                            <div className="client">
                                <h1>5482+</h1>
                                <h5>Satisfied Clients</h5>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>
            <div className="whyYouChooseUs">
               
                <div className="whyYouChooseContentSetion">
                    <span>WHY YOU CHOOSE US</span>
                    <h1>Why You Should Choose PrimeCare Alliance</h1>
                    <div className="whyYouchooseCards">
                        <div className="whyCards">
                            <div className="cardWhyImg">
                            <FaArrowAltCircleRight className='cardWhyImgIcon'  />
                            </div>
                            <div className='whyContent'>
                                <h4>Comprehensive Expertise</h4>
                                <h5> PrimeCare Alliance specializes solely in healthcare revenue cycle management, guaranteeing an unmatched grasp of the industry's.</h5>
                            </div>

                        </div>
                        <div className="whyCards">
                            <div className="cardWhyImg">
                            <FaArrowAltCircleRight className='cardWhyImgIcon'  />
                            </div>
                            <div className='whyContent'>
                                <h4>Holistic Solutions</h4>
                                <h5>PrimeCare Alliance delivers comprehensive solutions that go beyond revenue maximization.</h5>
                            </div>
                        </div>
                        <div className="whyCards">
                            <div className="cardWhyImg">
                            <FaArrowAltCircleRight className='cardWhyImgIcon'  />
                            </div>
                            <div className='whyContent'>
                                <h4>Proven Track Record</h4>
                                <h5>PrimeCare Alliance has a proven track record of success in helping healthcare organizations achieve their financial goals</h5>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="whyYouChooseImgSection">
                    <div id="triangle-bottomright"></div>
                    <div className="WhyChooseImg">
                        <img src={choose} alt="" />
                    </div>
                    <div id="triangle-topleft"></div>
                </div>
            </div>

            <div className="reviews">
                <h1>Reviwes</h1>
                <div className="reviewCard">



                    <Swiper navigation={true} modules={[Navigation]} loop={true} className="mySwiper">
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>If tasked with recommending a coding company renowned for its transparent operational approach and complemented by a cohesive team of exceptionally efficient members, my unequivocal choice would be PrimeCare Alliance.</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Henry Bennett  Manager, Medical Coding</h1>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>"I eagerly anticipate each workday, drawn to the vibrant atmosphere, energy, and enthusiasm exuded by my colleagues. PrimeCare Alliance stands out as the premier employer in our field, thanks to its commitment to appreciating and recognizing employees, coupled with a meticulously planned career trajectory. Continuous training initiatives facilitated my certification by the industry's leading authority."</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Raman rai  Manager, Medical Billing</h1>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>"In my role, I consistently encounter challenges that provide me with the chance to make a meaningful impact. There's nothing more fulfilling than this. Joining PrimeCare Alliance has revolutionized my career, offering focused growth opportunities, competitive compensation, and exposure to an enriching international work environment."</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Kunal sharma  Manager, Quality</h1>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviewLeft">
                                <div className="cooomaReview">
                                    <img src={coma} alt="" />
                                </div>
                                <div className="contentreview">
                                    <div className='pTag'>The exceptional work environment, characterized by a performance-driven culture and supportive colleagues, serves as a constant source of motivation for me to consistently deliver my best. Being part of an employee-centric organization like PrimeCare Alliance, I am not just employed, but on a fulfilling career path.</div>
                                    <h1><FaUserCircle className='userReviewIcon' />Anand T.  Process Executive</h1>
                                </div>

                            </div>
                        </SwiperSlide>

                    </Swiper>


                </div>
            </div>
        </>
    )

}
