import React from 'react'
import './AboutPages4.css'

import firstImg from "../assets/LRS.jpg"

export default function AboutPages4() {
    return (
        <>
            <div className="aboutPages4">
                <div className="headSpan4">
                    <span>PrimeCare Alliance Tomorrow</span>
                </div>

                <div className="firstSection4">
                    <div className="whyYouChooseImgSection4">
                        <div id="triangle-bottomright4"></div>
                        <div className="WhyChooseImg4">
                            <img src={firstImg} alt="" />
                        </div>
                        <div id="triangle-topleft4"></div>
                    </div>

                     <div className='contentFirst4'>
                        <div >PrimeCare Alliance is led by <span className='spanclass'>Mr. Lawrence R. Sherman</span> [Larry]. Larry has a unique and diversified back ground. He has worked in the healthcare industry for 30 years. He has held roles in various medical practices such as Vice President of Operations at a 60+ physicians with 20+ multi-specialty practices, CEO of a 20 provider specialty practice and Practice Administrator of a large rural Family Practice, OB/GYN practice, a Compliance Officer and Director of Contracting at a 100+ physician multi-specialty group.</div>
                        <div>Larry has been a principal in several medical billing companies as well as working at an executive level management in several large international outsource organizations. He has managed operations and processes in the United States and India during his career</div>
                    </div> 
                </div>



            </div>

        </>
    )
}
