import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Services from './page/Services';
import Navbar1 from './component/Navbar1';
import Navbar2 from './component/Navbar2';
import Home from './page/Home';
import Footer from './component/Footer';
import Contact from './page/Contact';
import About from './page/About';
import AboutPages from './page/AboutPages1';
import AboutPages1 from './page/AboutPages1';
import AboutPages2 from './page/AboutPages2';
import AboutPages3 from './page/AboutPages3';
import AboutPages4 from './page/AboutPages4';
import Insurance1 from './page/Insurance1';
import Insurance2 from './page/Insurance2';
import Insurance3 from './page/Insurance3';
import Insurance4 from './page/Insurance4';
import Insurance5 from './page/Insurance5';
import Career from './page/Career';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './component/ScrollToTop';


function App() {

   window.addEventListener("contextmenu", (e) => e.preventDefault());
  return (
    <>

      <Router>
        <ScrollToTop/>
        <Navbar1 />
        <Navbar2 />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Northwest-Today" element={<AboutPages1 />} />
          <Route path="/Northwest-Tomorrow" element={<AboutPages2 />} />
          <Route path="/Passion-&-People" element={<AboutPages3 />} />
          <Route path="/Leadership" element={<AboutPages4 />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/Insurance1" element={<Insurance1 />} />
          <Route path="/Insurance2" element={<Insurance2 />} />
          <Route path="/Insurance3" element={<Insurance3 />} />
          <Route path="/Insurance4" element={<Insurance4 />} />
          <Route path="/Insurance5" element={<Insurance5 />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </Router>

    </>
  );
}

export default App;
