import React from 'react'
import './AboutPages2.css'

import firstImg from "../assets/health.jpg"
import secondImg from "../assets/first (2).jpg"

export default function AboutPages2() {
  return (
    <>
        <div className="aboutPages2">
                <div className="headSpan2">
                    <span>PrimeCare Alliance Tomorrow</span>
                </div>
              
                <div className="secondSection2">
                    <div className="secondImg2">
                        <img src={secondImg} alt="" />
                    </div>
                    <div className="ContentSecond2">
                        <h4>An PrimeCare Alliance client will not be willing to sacrifice superior quality for the appeal of a lower price. For this reason, PrimeCare Alliance will hire the best, continue to enhance their skills through additional training and instruct them with domain knowledge. PrimeCare Alliance's methodology will be infused into every process supporting our clients' operations. PrimeCare Alliance will continue to invest in the latest technology and ensure our operations have more than adequate redundancies as well as a business continuity plan is in place.</h4>

                        <div>PrimeCare Alliance will pursue new markets and geographies, continuously looking for new opportunities to bring higher value to our clients at competitive prices.</div>
                    </div>
                </div>
                <div className="firstSection2">
                   

                   <div className="FirstImgsection2">
                       <img src={firstImg} alt="" />
                   </div>
                   <div className='contentFirst2'>
                       In the future while being consistent with the past, PrimeCare Alliance will continue to focus on delivering the highest level of service to support the healthcare industry. From the beginning, PrimeCare Alliance understood that to provide value in support of a complete process, one must have proficient domain and process expertise across a cornacopia of interrelated processes that make up the big picture. Though there is great commonality in many tasks and processes across each healthcare specialty, the apex of that value is having the deep domain expertise in the client's specialty.
                       <br/>
                       PrimeCare Alliance does not strive to be the biggest…but to be the best. The organization of choice for both the healthcare entity as well as the employee. We aspire to be sought after as the most trusted name for Revenue Cycle Management support services in healthcare. We have a highly collaborative governance model ensures, our clients will never have an impression of "loss of control" but rather, a natural extension of their operations.
                   </div>

               </div>


            </div>

    </>
  )
}
